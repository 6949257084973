p {
  margin: 0;
}

body {
  font-size: 0.9rem;
}

.container {
  padding: 0 5px;
}

.font-size-medium-small {
  font-size: 0.85rem;
}

.font-size-small {
  font-size: 0.8rem;
}

.font-size-big {
  font-size: 1.1rem;
}
.font-size-verry-big {
  font-size: 1.3rem;
}

/* ====== btn border =======*/

.draw {
  overflow: hidden;
  position: relative;
  background: none;
  border: 0;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 3px #dadada;
  color: #dadada;
  margin: 0 1em;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
  padding: 5px;
}
.draw::before, .draw::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  border: 3px solid transparent;
  width: 0;
  height: 0;
}
.draw::before {
  top: 0;
  left: 0;
  border-top-color: #ff3d3d;
  border-right-color: #ff3d3d;
  animation: border 4s infinite;
}
.draw::after {
  bottom: 0;
  right: 0;
  animation: border 4s 2s infinite, borderColor 4s 2s infinite;
}

@keyframes border {
  0% {
    width: 0;
    height: 0;
  }
  25% {
    width: 100%;
    height: 0;
  }
  50% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}
@keyframes borderColor {
  0% {
    border-bottom-color: #ff3d3d;
    border-left-color: #ff3d3d;
  }
  50% {
    border-bottom-color: #ff3d3d;
    border-left-color: #ff3d3d;
  }
  51% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  100% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: center;  
}

:-ms-input-placeholder {  
  text-align: center; 
}