.wtw .item-tab {
    padding: 10px 0;
    display: block;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
}

.wtw .react-tabs__tab {
    flex: 0 0 25%;
    position: relative;
    font-size: 12px;
}

.wrapper-tab1-5 .input-ref {
    width: 90%;
    margin: auto;
    border-radius: 0;
}

.wtw .react-tabs {
    min-height: 500px;
}

.ant-picker-panel-container .ant-picker-time-panel .ant-picker-content ul:nth-child(4)  {
    display: none;
}

.wtwi {
    background-color: #5c2506;
}

.wtwi .ant-form-vertical .ant-form-item-label > label{
    color: #00d16c;
}