.footer-page {
    width: 100%;
    /* background: #009620; */
    color: white;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    font-weight: 600;
    font-size: 18px;
}