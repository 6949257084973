.wrapper-img-guide {
    position: relative;
}

.wrapper-img-guide .img-gif {
    width: 100px;
    transform: translate(-50%, -50%) scaleX(-1);
    left: 48.5%;
    top: 50%;
    position: absolute;
}

.main-content {
    padding-bottom: 60px;
}

.react-tabs__tab {
    /* width: calc((100%-6px) / 3); */
    /* width: -webkit-calc((100%-6px) / 3);
    width: -moz-calc((100%-6px) / 3); */
    flex: 0 0 33.333333%;
    text-align: center;
    border-radius: 0;
    border: none;
    bottom: 0;
    color: #777;
    font-weight: 600;
    padding: 0;
    /* margin-right: 6px; */
    position: relative;
}

.main-content .react-tabs {
    border: 1px solid #ccc;
    box-shadow: -2px 3px 16px #888888;
}

.main-content .react-tabs__tab-list {
    border-bottom: 1px solid #eaeaea;
    margin: 0 0 10px;
    padding: 0;
    display: flex;
}

.item-tab {
    padding: 5px 0;
    display: block;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
}

.btn-custom {
    border-radius: 0 !important;
    width: 140px;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.wrapper-tab2 .input-ref {
    width: 90%;
    margin: auto;
    border-radius: 0;
}

.main-content .react-tabs__tab:after {
    content: "";
    width: 94%;
    height: 3px !important;
    background: #9c9c9c;
    display: block;
    position: absolute;
    bottom: 0 !important;
    left: 3% !important;
}

.main-content .react-tabs__tab--selected {
    color: #487ffa;
}

.main-content .react-tabs__tab--selected:after {
    background: #487ffa !important;
}

.main-content .item-success {
    color: #009431
}

.main-content .item-success:after {
    background: #009431 !important;
}

.react-tabs__tab:focus {
    box-shadow: none;
}

.react-tabs__tab:last-child {
    margin: 0;
}

#tabList {
    display: flex;
}

.main-content .table td, .table th {
    padding: 0.5rem;
}