.main-contain {
    padding: 10px 10px;
    border: 1px solid #ccc;
    box-shadow: -2px 3px 16px #888888;
}

.glc-btn-next {
    /* border-radius: 1000 !important; */
    width: 140px;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.glc-input-ref {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    color: rgb(0, 0, 0);
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}
